import { Link } from 'react-router-dom';
import categories from '../../../data/Categories';
import './Model.css';

export default function Model({ model, categoryName, subCategoryName }) {
    // Helper function to find subcategory name by model id and category name
    const findSubCategoryName = (categoryName, modelId) => {
        const category = categories.find(cat => cat.name === categoryName); // Find the category
        if (category) {
            const subCategory = category.subCategories.find(sub => sub.models.some(m => m.id === modelId)); // Find the subcategory containing the model
            return subCategory ? subCategory.name : null; // Return the subcategory name if found
        }
        return null;
    };

    // Map default models to their corresponding categories and subcategories
    const defaultModelMapping = {
        991: { category: 'parquet', subCategory: 'stratifié' },
        992: { category: 'gazon artificiel', subCategory: 'decoratif' },
        993: { category: 'placo platre', subCategory: 'papierPeint' },
        994: { category: 'gerflex (vinyle)', subCategory: 'residentiel' },
        995: { category: 'tapis paillasson', subCategory: 'spaghetti' },
        996: { category: 'bois extérieur', subCategory: 'bambou' },
        997: { category: 'bardage - habillage', subCategory: 'wpc' },
        998: { category: 'store', subCategory: 'papierPeint' },
    };

    const externalLinks = {
        31: 'https://www.gerflor.ma/fr/produits/mipolam-affinity',
        
    };

    // Check if the model is part of the default models
    const isDefaultModel = model.id in defaultModelMapping;

    // Find subcategory name if it's not a default model
    const subCategoryNameForModel = isDefaultModel
        ? defaultModelMapping[model.id].subCategory
        : findSubCategoryName(categoryName, model.id);

    // Construct the link path based on whether it's a default model or not
    const linkPath = isDefaultModel
        ? `/products/${defaultModelMapping[model.id].category}/default`
        : `/decors/${categoryName}/${subCategoryNameForModel}/${model.id}`;

    // Handle model click
    const handleClick = (e) => {
        if (model.id in externalLinks) {
            e.preventDefault(); // Prevent the default Link navigation
            window.open(externalLinks[model.id], '_blank'); // Open the external link in a new tab
        }
    };

    return (
        <Link key={model.id} to={linkPath} onClick={handleClick}>
            <div className="product">
                <div>
                    <img src={'../../' + model.image} alt={model.name} />
                    <header>{model.name}</header>
                </div>
            </div>
        </Link>
    );
}
