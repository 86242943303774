import './Products.css';
import ProductsList from './ProductsList/ProductsList';
import SideNavbar from './SideNavbar/SideNavbar';
import categories from '../../data/Categories';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

export default function Products() {
    useEffect(() => {
        window.scrollTo(0, 0); // Ensure the page starts at the top
    
        const timer = setTimeout(() => {
          let currentScroll = 0; // Start position
          const targetScroll = 230; // Distance to scroll
          const step = 7; // Number of pixels to move per interval
          const interval = 20; // Interval in milliseconds for the scroll
    
          const scrollInterval = setInterval(() => {
            if (currentScroll < targetScroll) {
              window.scrollBy(0, step); // Scroll down by `step` pixels
              currentScroll += step; // Update the current scroll position
            } else {
              clearInterval(scrollInterval); // Stop scrolling when the target is reached
            }
          }, interval);
        }, 2000); 
    
        return () => clearTimeout(timer);
      }, []);
    const defaultModels = [
        { id: 991, name: 'PARQUET', image: './images/models/concept-neo/moderna/ambiance-moderna.png' },
        { id: 992, name: 'GAZON ARTIFICIEL', image: './images/models/castor/ambiance-castor.jpg' },
        { id: 993, name: 'PLACO PLATRE', image: './images/products/placo.jpg' },
        { id: 994, name: 'GERFLEX', image: './images/products/gerflex.jpg' },
        { id: 995, name: 'paillasson - Moquette', image: './images/models/zemat/ambiance-zemat-blue.jpg' },
        { id: 996, name: 'Bois Extérieur', image: './images/home/home-1.png' },
        { id: 997, name: 'Bardage', image: './images/products/bardage-2.png' },
        { id: 998, name: 'store', image: './images/products/store.webp' },
    ];

    const { categoryName, subCategoryName } = useParams(); // Capture category and subcategory from the URL
    const [modelsBySubCategory, setModelsBySubCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategoryName, setSelectedSubCategoryName] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        if (!categoryName) {
            // No category in the URL, show default models
            setModelsBySubCategory([
                {
                    subCategoryName: 'produits',
                    models: defaultModels,
                },
            ]);
            setSelectedSubCategoryName('');
        } else if (categoryName && subCategoryName == 'default') {
            // If only category is present in the URL
            const category = categories.find(
                (cat) => cat.name.toLowerCase() === categoryName.toLowerCase()
            );
    
            if (category) {
                // Show all subcategories for the selected category
                const groupedModels = category.subCategories.map((subCategory) => ({
                    subCategoryName: subCategory.name,
                    models: subCategory.models,
                }));
    
                setModelsBySubCategory(groupedModels);
                setSelectedCategory(category);
                setSelectedSubCategoryName(''); // Clear specific subcategory selection
            } else {
                // Handle case where category is not found
                navigate('/products'); // Redirect to default products if category is not found
            }
        } else {
            // If category and subcategory are both present in the URL
            const category = categories.find(
                (cat) => cat.name.toLowerCase() === categoryName.toLowerCase()
            );
    
            if (category) {
                const subCategory = category.subCategories.find(
                    (sub) => sub.name.toLowerCase() === subCategoryName.toLowerCase()
                );
    
                if (subCategory) {
                    setModelsBySubCategory([
                        {
                            subCategoryName: subCategory.name,
                            models: subCategory.models,
                        },
                    ]);
                    setSelectedCategory(category);
                    setSelectedSubCategoryName(subCategory.name);
                } else {
                    // Handle case where subcategory is not found
                    navigate('/products'); // Redirect to default products if subcategory is not found
                }
            }
        }
    }, [categoryName, subCategoryName, navigate]);

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);

        // Group models by subcategory
        const groupedModels = category.subCategories.map((subCategory) => ({
            subCategoryName: subCategory.name,
            models: subCategory.models,
        }));

        setModelsBySubCategory(groupedModels);
        setSelectedSubCategoryName(''); // Clear selected subcategory name when a new category is selected
    };

    const handleSubCategorySelect = (subCategory) => {
        setModelsBySubCategory([
            {
                subCategoryName: subCategory.name,
                models: subCategory.models,
            },
        ]);
        setSelectedSubCategoryName(subCategory.name); // Track the selected subcategory name
    };

    return (
        <div className="products">
            <div>
                <p>Quand l'élégance s'exprime, elle choisit Decopa</p>
            </div>
            <div>
                <SideNavbar
                    categories={categories}
                    selectedCategory={selectedCategory}
                    onCategorySelect={handleCategorySelect}
                    onSubCategorySelect={handleSubCategorySelect}
                />
                <ProductsList
                    modelsBySubCategory={modelsBySubCategory}
                    categoryName={selectedCategory?.name || 'Produits'}
                    subCategoryName={selectedSubCategoryName}
                />
            </div>
        </div>
    );
}
